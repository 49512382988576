import React from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet-async'
import './style.scss'

const CVURL = `${process.env.PUBLIC_URL}/DwayneAnderson-EmploymentHistory.pdf`

const About = () => {
  return (
    <div className="About">
      <Helmet>
        <title>Dwayne Anderson: My Story</title>
      </Helmet>
      <h1>About Dwayne</h1>
      <p><em>TLDR</em> - View my <a href={CVURL} target="_blank" rel="noopener noreferrer">employment history</a></p>
      <p>
        I was born and raised in Oak Ridge, Tennessee.
      </p>
      <p>
        As a young adult I worked in the printing industry during the period it was transitioning to digital technologies.
        I was more than happy to learn the ins and outs of both the old and new ways of doing things. I started as a press operator, moved to pre-press/darkroom, and ended up in the art department.
        I eventually started doing freelance graphic design.
      </p>
      <p>
        Meanwhile, the world wide web was picking up steam.
        It wasn't long before a client asked if I could build a web site, and I was delighted to give it a go.
        I was instantly hooked.
        I built another site.
        And another... And another...
        Before long I had my own little company building web sites.
        After a couple years I was offered a job at Tubatomic Studios, who had just won an Apple Design Award.
        We were building database-driven Flash sites for record labels and household brands.
        How exciting!
      </p>
      <p>
        In 2007, just as "web 2.0" was taking off, I moved to San Francisco, California to accept a job as UI Designer at Digg.com.
      </p>
      <p>
        Since that time my primary practice has transitioned from design to engineering. I worked at more startups, did some time as a prototyping engineer at eBay, and even tried my hand at teaching.
      </p>
      <p>
        In 2013, I joined a pre-launch startup called Opsmatic.
        As their first non-founder hire I helped to design and develop their MVP offering, in addition to subsequent interations thereof.
      </p>
      <p>
        In 2015, Opsmatic was acquired by New Relic and our product became the basis for their newest offering, New Relic Infrastructure.
      </p>
      <p>
        In 2017, I decided to take time off from the industry.
        I took up woodworking.
        I repaired and sold vinage hi-fi and audio recordig gear.
        I played guitar in three bands, recorded albums, and went on tour.
      </p>
      <p>
        In 2019, Jay Adelson invited me to help out with his latest venture, Scorbit, concerned with connecting pinball machines -- both new and old -- to the internet.
        The project launched in 2020 and has been well-received by the pinball community.
      </p>
      <p>
        I am currently seeking additional contract opportunities.
        I am most interested in early-stage projects. (Prototyping and MVPs.)
        I am especially interested in physical products.
        I would especially, especially welcome any opportunity that affords me the opportunity to learn and practice WebGL or other 3D tools.
      </p>
      <p>
        If you, dear reader, have a project that could benefit from my assistance, I'd be honored and delighted to discusss it!
        Please do not hesitate to <Link to="/contact">get in touch</Link>.
      </p>
    </div>
  )
}

export default About