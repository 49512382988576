import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import BurgerSVG from './../../assets/img/burger.svg'
import CloseSVG from './../../assets/img/close.svg'
import './style.scss'

const CVURL = `${process.env.PUBLIC_URL}/DwayneAnderson-EmploymentHistory.pdf`
let interval = null

const Links = (props) => {
  const {setIsOpen} = props

  const closeMenu = () => {
    if (setIsOpen) {
      setTimeout(() => {
        setIsOpen(false)
      }, 100)
    }
  }

  return (
    <ul>
      <li className="Header-Nav-Item Header-Nav-Item--mobileOnly" onClick={closeMenu}>
        <Link to="/">Home</Link>
      </li>
      <li className="Header-Nav-Item" onClick={closeMenu}>
        <Link to="/about">About</Link>
      </li>
      <li className="Header-Nav-Item" onClick={closeMenu}>
        <Link to="/projects">Projects</Link>
      </li>
      <li className="Header-Nav-Item" onClick={closeMenu}>
        <a href={CVURL} target="_blank" rel="noopener noreferrer">CV</a>
      </li>
      <li className="Header-Nav-Item" onClick={closeMenu}>
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
  )
}

const BurgerMenu = (props) => {
  const {isOpen, setIsOpen} = props

  return (
    <div className="BurgerMenu">
      {!isOpen
        ? <button
            className="BurgerMenu-Trigger"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img src={BurgerSVG} alt="Close Menu" />
          </button>
        : <button
            className="BurgerMenu-Trigger"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img src={CloseSVG} alt="Open Menu" />
          </button>
      }
      {isOpen ? <Links setIsOpen={setIsOpen} /> : null}
    </div>
  )
}

const Header = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    setInterval(() => {
      setWidth(window.innerWidth)
    }, 10)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <header className="Header">
      <nav className="Header-Nav">
        <div className="Header-Nav-Item Header-Nav-Item--Home">
          <Link to="/">Dwayne Anderson</Link>
        </div>
        {width < 800
          ? <BurgerMenu
              isOpen={menuOpen}
              setIsOpen={setMenuOpen}
            />
          : <Links />
        }
      </nav>
    </header>
  )
}

export default Header