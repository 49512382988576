import React from 'react'
import {Helmet} from 'react-helmet-async'
import DiceScreenShot1 from './../../assets/img/dice-screenshot-1.png'
import DiceRoll1 from './../../assets/img/dice-roll-1.gif'
import DiceCode from './../../assets/img/dice-code.png'
import ScorbitVision8upScoreboard from './../../assets/img/scorbit-vision-8up-scoreboard.png'
import ScorbitVision3upLeaderboard from './../../assets/img/scorbit-vision-3up-leaderboard.png'
import ScorbitVisionStreamboard from './../../assets/img/scorbit-vision-streamboard.png'
import ScorbitVisionAdmin from './../../assets/img/scorbit-vision-admin.png'
import OpsmaticTimeline from './../../assets/img/opsmatic-timeline.png'
import OpsmaticInventory from './../../assets/img/opsmatic-inventory.png'
import OpsmaticAssertions from './../../assets/img/opsmatic-assertions.png'
import OpsmaticNewRelic from './../../assets/img/opsmatic-newrelic.png'
import SofarOceanHome from './../../assets/img/sofar-ocean-home.png'
import SofarOceanScreens from './../../assets/img/sofar-ocean-screens.png'
import './style.scss'

const Projects = () => {
  return (
    <div className="Projects">
      <Helmet>
        <title>Dwayne Anderson: Projects</title>
      </Helmet>
      <h1>Projects</h1>
      <p>A small sampling of some of my favorite projects</p>
      <div className="Projects-Project">
        <h2>ScorbitVision</h2>
        <p>Built in React and designed for large screens, ScorbitVision is a component of the <a href="https://scorbit.io/">Scorbit</a> pinball platform that provides for real-time display of game state for pinball arcades, tournaments, and streamers. Working under tight time constraints, I was the sole designer and front end engineer on this project.</p>
        <div className="flexRow flexRow--2 flexRow--wrap">
          <figure className="figure--small">
            <img src={ScorbitVision8upScoreboard} alt="Screenshot: ScorbitVision Scoreboard" />
            <figcaption>Scoreboards display live game data</figcaption>
          </figure>
          <figure className="figure--small">
            <img src={ScorbitVision3upLeaderboard} alt="Screenshot: Scorbitvision Leaderboard" />
            <figcaption>Leaderboards display top scores</figcaption>
          </figure>
          <figure className="figure--small">
            <img src={ScorbitVisionStreamboard} alt="Screenshot: ScorbitVision Streamboard" />
            <figcaption>Stream Tools allow for streamers to integrate game data in streaming tools like OBS</figcaption>
          </figure>
          <figure className="figure--small">
            <img src={ScorbitVisionAdmin} alt="Screenshot: ScorbitVision Admin" />
            <figcaption>The ScorbitVision admin interface allows users to create and manage their ScorbitVision assets</figcaption>
          </figure>
        </div>
      </div>
      <div className="Projects-Project">
        <h2>Opsmatic</h2>
        <p>The Opsmatic platform was designed to radically improve visibility and collaboration across operations and development teams. This was accomplished by providing a precise, real-time picture of all changes that affect a company's infrastructure and a detailed, searchable index of the live state of their hosts.</p>
        <p>
          I joined the company as the first non-founder hire. In the early days I wore two hats -- UI Designer and Front End Engineer. As the company grew, I transitioned to a fully development-focused role.
          As the the sole front-end engineer, I built the entirety of the Opsmatic dashboard in Backbone.js. The interface allowed users to analyze both real-time and historical state of their infrastructure, comprised of data collected by the Opsmatic Agent running on their hosts.
        </p>
        <p>Opsmatic was acquired by New Relic in 2015. For more information about Opsmatic's functionality, please see an archived copy of <a href="https://web.archive.org/web/20151203092342/https://opsmatic.com/product" target="_blank">Opsmatic.com's product page</a> on Archive.org.</p>
        <figure className="figure">
          <div className="video">
            <iframe src="https://www.youtube.com/embed/AKGW13Cgnbg?start=165" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
            <figcaption>Video: Jim Stoneham presents a demo of Opsmatic at FutureStack 2015.</figcaption>
        </figure>
        <div className="flexRow flexRow--2 flexRow--wrap">
          <figure className="figure--small">
            <img src={OpsmaticTimeline} alt="Opsmatic Timeline View" />
            <figcaption>Opsmatic Timeline View</figcaption>
          </figure>
          <figure className="figure--small">
            <img src={OpsmaticInventory} alt="Opsmatic Inventory View" />
            <figcaption>Opsmatic Inventory View</figcaption>
          </figure>
          <figure className="figure--small">
            <img src={OpsmaticAssertions} alt="Opsmatic Assertions View" />
            <figcaption>Opsmatic Assertions View</figcaption>
          </figure>
          <figure className="figure--small">
            <img src={OpsmaticNewRelic} alt="New Relic Infrastructure" />
            <figcaption>Opsmatic became a core part of New Relic Infrastructure</figcaption>
          </figure>
        </div>
      </div>
      <div className="Projects-Project">
        <h2>SofarOcean</h2>
        <p>Working with Sofar Ocean's product design and marketing teams, I provided front-end engineering (HTML / CSS / WebFlow / Responsive) for a refresh of Sofar Ocean's marketing web site at <a href="https://sofarocean.com/" target="_blank">SofarOcean.com</a>.</p>
        <div className="flexRow flexRow--2">
          <figure className="figure--small">
            <img src={SofarOceanHome} alt="SofarOcean.com homepage video hero" />
            <figcaption>Sofar Ocean's home page features a video background</figcaption>
          </figure>
          <figure className="figure--small">
            <img src={SofarOceanScreens} alt="Sampling of content pages on SofarOcean.com" />
            <figcaption>A sampling of pages from SofarOcean.com website</figcaption>
          </figure>
        </div>
      </div>
      <div className="Projects-Project">
        <h2>Quarantine Dice Game</h2>
        <p>Quarantine Dice Game is a clone of a popular dice game created for friends to play over Zoom or Google Meet during the COVID-19 pandemic.</p>
        <figure>
          <img src={DiceScreenShot1} alt="Screenshot: Quarantime Dice Game" />
          <figcaption>Quarantine Dice Game closely resembles a classic game known and loved the world over.</figcaption>
        </figure>
        <div className="flexRow flexRow--2">
          <figure className="figure--small">
            <img src={DiceRoll1} alt="Screenshot: Quarantime Dice Game" />
            <figcaption>Quarantine Dice Game uses SVG graphics and CSS animations.</figcaption>
          </figure>
          <figure className="figure--small">
            <img src={DiceCode} alt="Screenshot: Quarantime Dice Game" />
            <figcaption>Quarantine Dice Game is built with JavaScript and uses the popular React.js framework.</figcaption>
          </figure>
        </div>
      </div>
    </div>
  )
}

export default Projects