import React from 'react'
import './style.scss'

const NotFound = () => {
  return (
    <div className="NotFound">
      <h1>OH NO! 404!</h1>
      <p>The requested url could not be found.</p>
    </div>
  )
}

export default NotFound
