import React from 'react'
import Obfuscate from 'react-obfuscate'
import {Helmet} from 'react-helmet-async'
import './style.scss'

const Contact = () => {
  return (
    <div className="Contact">
      <Helmet>
        <title>Dwayne Anderson: Contact</title>
      </Helmet>
      <h1>Let's Connect!</h1>
      <p>Email is the best way to reach me.</p>
      <p>Feel free to reach out on LinkedIn as well.</p>
      <dl className="Contact-Methods">
        <div className="Contact-Methods-Method Contact-Methods-Method--Email">
          <dt>Email</dt>
          <dd>
            <Obfuscate
              email="dwayne.anderson@gmail.com"
              headers={{
                subject: 'HOWDY!',
              }}
            />
          </dd>
        </div>
        <div className="Contact-Methods-Method Contact-Methods-Method--LinkedIn">
          <dt>LinkedIn</dt>
          <dd>
            <a href="https://linkedin.com/in/dwayneanderson" target="_blank" rel="noopener noreferrer">
              linkedin.com/in/dwayneanderson
            </a>
          </dd>
        </div>
      </dl>
    </div>
  )
}

export default Contact