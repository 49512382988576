import React from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import {HelmetProvider} from 'react-helmet-async'
import Header from './../Header'
import Footer from './../Footer'
import Home from './../Home'
import About from './../About'
import Projects from './../Projects'
import Contact from './../Contact'
import NotFound from './../404'
import './style.scss'

const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />
        <div className="App-Content">
          <HelmetProvider>
            <Switch>
              <Route
                exact
                path="/"
                key="home"
                render={(props) => <Home />}
              />
              <Route
                exact
                path="/projects"
                key="projects"
                render={(props) => <Projects />}
              />
              <Route
                exact
                path="/about"
                key="about"
                render={(props) => <About />}
              />
              <Route
                exact
                path="/contact"
                key="contact"
                render={(props) => <Contact />}
              />
              <Route
                key="404"
                render={(props) => <NotFound />}
              />
            </Switch>
          </HelmetProvider>
        </div>
        <Footer />
      </Router>
    </div>
  )
}

export default App