import React from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet-async'
import './style.scss'

const CVURL = `${process.env.PUBLIC_URL}/DwayneAnderson-EmploymentHistory.pdf`

const Home = () => {
  return (
    <div className="Home">
      <Helmet>
        <title>Dwayne Anderson: Home</title>
      </Helmet>
      <h1>
        <span class="waves" role="img" aria-label="hello">👋</span>
        HOWDY!
      </h1>
      <p>
        I'm Dwayne. It's nice to meet you!
      </p>
      <p>
        I'm a software engineer in San Francisco focused
        on front end development.
      </p>
      <p>
        I primarily work in JavaScript/React, but I have also
        worked as a UI Designer and full-stack developer.
      </p>
      <p>
        I am also a musician, pinball enthusiast,
        and aspiring electrical engineer.
      </p>
      <p>Please feel free to:</p>
      <nav>
        <ul>
          <li>
            <Link to="/about">
              <span role="img" aria-label="emoji-book">📖</span>
              Read my story
            </Link>
          </li>
          <li>
            <a href={CVURL} target="_blank" rel="noopener noreferrer">
              <span role="img" aria-label="emoji-paper">📄</span>
              Download my C.V.
            </a>
          </li>
          <li>
            <Link to="/projects">
              <span role="img" aria-label="emoji-folder">📂</span>
              Peruse my projects
            </Link>
          </li>
          <li>
            <Link to="/contact">
              <span role="img" aria-label="emoji-speech-bubble">💬</span>
              Get in touch
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Home