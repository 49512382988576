import React from 'react'
import './style.scss'

const Footer = () => {
  return (
    <footer className="Footer">
      © 2021 Dwayne Anderson. All rights reserved.
    </footer>
  )
}

export default Footer