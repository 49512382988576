import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

// redirect naked domain to www
// redirect http to https
const {NODE_ENV} = process.env
const {protocol, href, pathname} = document.location
const isSecure = protocol.match(/^https/) || NODE_ENV.match(/development/)
if (NODE_ENV !== 'development' && (!href.match(/www/) || !isSecure)) {
  document.location.href = `https://www.dwayneanderson.com${pathname}`
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
